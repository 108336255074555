// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-js": () => import("./../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-services-advice-js": () => import("./../src/pages/services/advice.js" /* webpackChunkName: "component---src-pages-services-advice-js" */),
  "component---src-pages-services-disinfectionconsultancy-js": () => import("./../src/pages/services/disinfectionconsultancy.js" /* webpackChunkName: "component---src-pages-services-disinfectionconsultancy-js" */),
  "component---src-pages-services-js": () => import("./../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-researchanddevelopment-js": () => import("./../src/pages/services/researchanddevelopment.js" /* webpackChunkName: "component---src-pages-services-researchanddevelopment-js" */),
  "component---src-pages-story-js": () => import("./../src/pages/story.js" /* webpackChunkName: "component---src-pages-story-js" */),
  "component---src-pages-team-js": () => import("./../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-terms-js": () => import("./../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-thanks-js": () => import("./../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */)
}

