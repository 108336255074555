module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1140,"quality":80,"withWebp":false,"backgroundColor":"none","linkImagesToOriginal":false,"loading":"eager","disableBgImage":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/src/utils/locales/","languages":["en","nl","fr"],"defaultLanguage":"nl","redirect":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"LoWatter","short_name":"LoWatter","description":"Bestrijding van legionella in kraantjeswater.","start_url":"/nl/","lang":"nl","background_color":"#4DD996","theme_color":"#4DD996","display":"minimal-ui","icon":"src/images/favicon.png","localize":[{"start_url":"/en/","lang":"en","name":"LoWatter","short_name":"LoWatter","description":"Controlling legionella in tapwater."}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
